<template>
  <div>
    <b-button :size="size" @click="showModal" variant="dark"
      >{{ text }}<feather-icon class="ml-1" icon="UploadIcon"></feather-icon>
    </b-button>

    <b-modal
      v-model="isMediaSelectWindow"
      no-close-on-backdrop
      size="lg"
      @hidden="modalClosed"
      id="media-upload-modal"
      centered
      :title="text"
      ok-only
      ok-title="Select"
    >
      <b-card-text>
        <vue-select-image
          w="100px"
          h="100px"
          :dataImages="medias"
          :is-multiple="isMultiple"
          @onselectimage="onSelectImage"
          @onselectmultipleimage="onSelectMultipleImage"
          :selectedImages="isMultiple ? selectedMedia : [selectedSingleMedia]"
        >
        </vue-select-image>

        <div class="text-center mt-1">
          <b-pagination
            v-model="page"
            :total-rows="rows"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BOverlay,
  BModal,
  BCardText,
  BRow,
  BCol,
  BImg,
  BPagination,
} from "bootstrap-vue";
import { getUserToken } from "@/auth/utils";
import VueSelectImage from "vue-select-image";
import "vue-select-image/dist/vue-select-image.css";
import axios from "axios";

export default {
  props: {
    text: {
      type: String,
      default: "Select Media",
    },
    size: {
      type: String,
      default: "md",
    },
    isMultiple: {
      type: Boolean,
      default: true,
    },
    selectedMediaFiles: {
      type: Array,
      default() {
        return [];
      },
    },
    selectedSingleMediaFile: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      isMediaSelectWindow: false,
      medias: [],
      rows: 0,
      selectedMedia:
        this.selectedMediaFiles.length > 0 ? this.selectedMediaFiles : [],
      selectedSingleMedia:
        this.selectedSingleMediaFile && this.selectedSingleMediaFile.src
          ? this.selectedSingleMediaFile
          : {},
      page: 1,
      perPage: 20,
    };
  },
  components: {
    BButton,
    BOverlay,
    BModal,
    BCardText,
    VueSelectImage,
    BRow,
    BCol,
    BImg,
    BPagination,
  },
  watch: {
    page() {
      this.getMedia();
    },
  },
  methods: {
    removeFile() {},
    getMedia() {
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/api/gallery/get?page=${this.page}&size=${this.perPage}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          let mediaFiles = [];
          let gallery = response?.data?.gallery || [];
          for (let i = 0; i < gallery.length; i++) {
            const galleryItem = gallery[i];
            mediaFiles.push({
              id: galleryItem._id,
              src: galleryItem.url,
              key: galleryItem.key,
            });
          }
          this.medias = mediaFiles;
          this.rows = response.data.totalCount;
        })
        .catch((error) => {
          console.log(error.response.data);
        });
    },
    showModal() {
      this.isMediaSelectWindow = true;
    },
    onSelectImage() {},
    onselectmultipleimage() {},
    modalClosed() {
      if (this.isMultiple) {
        if (this.selectedMedia.length > 0) {
          let multipleMediaObject = {
            status: true,
            media: this.selectedMedia,
          };
          if (this.params) {
            multipleMediaObject.params = this.params;
          }
          this.$emit("mediaSelected", multipleMediaObject);
        } else {
          this.$emit("mediaSelected", { status: false });
        }
      } else {
        if (this.selectedSingleMedia.src) {
          let singleMediaObject = {
            status: true,
            media: this.selectedSingleMedia,
          };
          if (this.params) {
            singleMediaObject.params = this.params;
          }
          this.$emit("mediaSelected", singleMediaObject);
        } else {
          this.$emit("mediaSelected", { status: false });
        }
      }
      this.getMedia();
    },
    getMediaType(media) {
      return (media.match(/[^\\\/]\.([^.\\\/]+)$/) || [null]).pop();
    },
    onSelectMultipleImage(media) {
      this.selectedMedia = media;
    },
    onSelectImage(media) {
      this.selectedSingleMedia = media;
    },
  },
  mounted() {
    this.getMedia();
  },
};
</script>

<style scoped></style>
